import React, { useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Main } from "layouts";
import Container from "components/Container";
import imageSrc from "images/join-waitlist/image.webp";
import bgUrl from "images/hero-header/staticBackground.svg";

// Importing the styles for input fields
import "styles/inputStyles.css";
import { submitToFlodesk } from "flodeskapi/createUserData";
import ReusableButton from "components/ReusableButton";

const joinWaitlist = () => {
  // Using Material-UI hooks for theme and media query
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    phoneNumber: "",
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  // Handler for input changes, updates form state and clears any existing error for the field
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (errors[name]) {
      const tempErrors = { ...errors };
      delete tempErrors[name];
      setErrors(tempErrors);
    }
    setForm({
      ...form,
      [name]: value,
    });
  };

  // Handler for form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setLoading(true);
        await submitToFlodesk(form.firstName, form.lastName, form.email, form.phoneNumber || "", form.company || "");
        setSubmitted(true);
      } catch (error) {
        // console.error(
        //   "Error submitting data to Flodesk:",
        //   error.message
        // );
      }
      setLoading(false);
    }
  };

  // Function to validate form fields
  const findFormErrors = () => {
    const newErrors = {};
    const regexEmail = /\S+@\S+\.\S+/;
    const regexPhone = /^(\+\d{1,2}\s?)?1?\-?\.?\(?\d{3}\)?[\-\.]?\d{3}[\-\.]?\d{4}$/;

    // First name validation
    if (!form.firstName || form.firstName === "") newErrors.firstName = "First name is required.";
    // Last name validation
    if (!form.lastName || form.lastName === "") newErrors.lastName = "Last name is required.";
    // Email validation
    if (!form.email || form.email === "") newErrors.email = "Email is required.";
    else if (!regexEmail.test(form.email)) newErrors.email = "Please enter a valid email address.";
    // Phone number validation
    if (form.phoneNumber && !regexPhone.test(form.phoneNumber))
      newErrors.phoneNumber = "Please enter a valid phone number.";

    return newErrors;
  };

  return (
    <Main>
      <Box sx={{ backgroundImage: `url(${bgUrl})`, width: "100%" }}>
        <Container pt={10}>
          {submitted ? (
            // Display success message after form submission
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* Success icon */}
              <Box
                component={"svg"}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#28303C"
                width={130}
                height={130}
                color={"success.light"}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                />
              </Box>
              {/* Success message */}
              <Typography component="p" variant="titleExtraBold" align={"center"} marginY={2}>
                Welcome to Appnigma!
              </Typography>
              <Typography
                component="p"
                variant="description"
                color={theme.palette.text.description2}
                align={"center"}
                maxWidth={600}
                mb={4}
              >
                You're on the waitlist. Stay tuned for updates and early access details. Thanks for joining us!
                <br />
              </Typography>
              <ReusableButton title="Back Home" link="/" />
            </Box>
          ) : (
            // Display form before submission
            <Grid container>
              {isMd && (
                <Grid md={6} bgcolor={"#F6F9FF"} borderRadius={"30px 0 0 30px"} overflow={"hidden"}>
                  <Box component="img" height="670px" width='498px' src={imageSrc} alt="Join the waitlist" loading="lazy"></Box>
                </Grid>
              )}
              <Grid item xs={12} md={6} bgcolor={"white"} borderRadius={isMd ? "0 30px 30px 0" : "30px"}>
                <Box p={3} alignItems="center">
                  {/* Form title */}
                  <Typography component="p" variant="titleExtraBold" textAlign={isMd ? "left" : "center"}>
                    Join Our Waitlist
                  </Typography>
                  {/* Form description */}
                  <Typography
                    component="p"
                    variant="description"
                    color={theme.palette.text.description2}
                    mt={2}
                    textAlign={isMd ? "left" : "center"}
                  >
                    Secure your spot on our waitlist and gain early access to a world where complex coding and costly
                    consulting are things of the past
                  </Typography>
                  {/* Form fields */}
                  <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    style={{
                      marginTop: "40px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                  >
                    {/* First name field */}
                    <input name="firstName" placeholder="First Name*" required onChange={handleChange} />
                    {errors.firstName && (
                      <span
                        style={{
                          color: "red",
                          marginTop: "-24px",
                        }}
                      >
                        {errors.firstName}
                      </span>
                    )}

                    {/* Last name field */}
                    <input name="lastName" placeholder="Last Name*" required onChange={handleChange} />
                    {errors.lastName && (
                      <span
                        style={{
                          color: "red",
                          marginTop: "-24px",
                        }}
                      >
                        {errors.lastName}
                      </span>
                    )}

                    {/* Email field */}
                    <input name="email" placeholder="Email Address*" required onChange={handleChange} />
                    {errors.email && (
                      <span
                        style={{
                          color: "red",
                          marginTop: "-24px",
                        }}
                      >
                        {errors.email}
                      </span>
                    )}

                    {/* Company field */}
                    <input name="company" placeholder="Company" onChange={handleChange} />

                    {/* Phone number field */}
                    <input name="phoneNumber" placeholder="Phone Number" onChange={handleChange} />
                    {errors.phoneNumber && (
                      <span
                        style={{
                          color: "red",
                          marginTop: "-24px",
                        }}
                      >
                        {errors.phoneNumber}
                      </span>
                    )}
                    {/* Submit button */}
                    <ReusableButton
                      title={loading ? "Please Wait..." : "Join Now"}
                      type="submit"
                      disabled={!form.firstName || !form.lastName || !form.email || !!Object.keys(errors).length}
                    />
                  </form>
                </Box>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </Main>
  );
};

export default joinWaitlist;
